// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-greece-tsx": () => import("./../../../src/pages/about-greece.tsx" /* webpackChunkName: "component---src-pages-about-greece-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-fee-tsx": () => import("./../../../src/pages/fee.tsx" /* webpackChunkName: "component---src-pages-fee-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-market-trend-tsx": () => import("./../../../src/pages/market-trend.tsx" /* webpackChunkName: "component---src-pages-market-trend-tsx" */),
  "component---src-pages-need-to-know-tsx": () => import("./../../../src/pages/need-to-know.tsx" /* webpackChunkName: "component---src-pages-need-to-know-tsx" */),
  "component---src-pages-properties-index-tsx": () => import("./../../../src/pages/properties/index.tsx" /* webpackChunkName: "component---src-pages-properties-index-tsx" */),
  "component---src-pages-properties-p-tsx": () => import("./../../../src/pages/properties/p.tsx" /* webpackChunkName: "component---src-pages-properties-p-tsx" */),
  "component---src-pages-seminar-tsx": () => import("./../../../src/pages/seminar.tsx" /* webpackChunkName: "component---src-pages-seminar-tsx" */)
}

